@import 'assets/scss/variables.scss';

.sidenav {
  height: 100vh;
  width: 40%;
  position: absolute;
  opacity: 0;
  background-color: $black;
  border-left: 3px solid $gold;
  transition: .3s all;
  top: 0;
  right: 0;
  z-index: -1;

  &.show {
    opacity: 1;
    z-index: 9999;
  }

  @include sm {
    width: 100%;
  }

  div.wrap {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .btn-cart {
      margin: auto 0 2rem 0;
      width: calc(100% - 2rem);
    }

    .logo-nav {
      margin-top: 2rem;
      max-height: 93px;
    }

  }

  .close-menu {
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    @extend .btn-transparent;
  }

  .cart-items {
    overflow-y: auto;
    max-height: 76vh;

    ol.list {
      margin: 0;
      padding: 0;

      li {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        position: relative;
        transition: .3s all;

        .top {
          font-family: $font-btn;
          text-transform: uppercase;
          letter-spacing: 2px;
          display: flex;
          justify-content: space-between;
          transition: .3s all;

          .first {
            width: 60%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .second {
            width: 20%;
            text-align: center;
          }

          .third {
            width: 20%;
            text-align: right;
          }

          &.header {
            color: $gold;
          }

          &.totals {
            border-top: 1px solid $gold;
            margin-top: 1rem;
            padding: .5rem 0;

            .first {
              color: $gold
            }
          }
        }

        .bottom {
          font-size: .9rem;
          letter-spacing: 1px;
          color: $gold;
          transition: .3s all;
        }

        .delete {
          position: absolute;
          right: 0;
          background: $red;
          width: 45px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 30px;
          cursor: pointer;
          visibility: hidden;

          svg {
            width: 14px;
            height: auto;
          }
        }

        &:hover {

          .top,
          .bottom {
            opacity: .5;
          }

          .delete {
            visibility: visible;
          }
        }
      }
    }
  }

}