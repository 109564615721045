@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';

//Global Styles
body {
  background: $body-bg;
  font-family: $font-body;
  color: $white;
}

h1,
h2,
h3,
h4 {
  font-weight: lighter;
  font-family: $font-btn;
  color: $gold;
}

*:focus {
  outline: none !important;
}

::placeholder {
  color: $gold !important;
}


.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

a {
  transition: .3s all;
}

h4 {
  font-family: $font-btn;
  color: $gold;
  font-size: 1.25rem;
  letter-spacing: 1px;
}

.headline {
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 1px;

  @include sm {
    font-size: 1.2rem;
  }
}

.btn {
  font-family: $font-btn;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 0;
  transition: .3s all;
  border-width: 2px;

  &:hover {
    background: $gold;
    border: $gold;
  }

  &.btn-cart {
    height: 65px;
  }
}

input,
select,
textarea,
.form-control {
  background: transparent;
  border: 2px solid $gold;
  border-radius: 0;
  color: $gold;
  text-align: center;
  font-size: 20px;
  transition: .3s all;
  border-radius: 0;

  &:focus {
    background: $gold;
    color: black;
  }
}

.full-width {
  width: 100%;
}

.mt-6 {
  margin-top: 5rem;
}


.products-details-wrap {
  .product-info {}

  .product-image-wrap {
    img {
      width: 100%;
      height: auto;
    }
  }

  @include sm {
    flex-direction: column-reverse;
    padding-bottom: 93px;

    #product-options {
      flex-direction: column !important;
    }

    .product-image-wrap {
      padding-bottom: 40px;
    }
  }
}

label,
.label {
  font-family: $font-btn;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 1rem;
}

.option-wrap {

  .controls {
    align-items: center;

    button.increment {
      color: $gold;
      font-weight: bold;
      font-size: 31px;
      width: 40px;
      padding: 0;
    }

    input[type=number] {
      width: 65px;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    select {
      -webkit-appearance: none;
      margin: 0;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image:
        linear-gradient(45deg, transparent 50%, $gold 50%),
        linear-gradient(135deg, $gold 50%, transparent 50%);
      background-position:
        calc(100% - 15px) 18px,
        calc(100% - 10px) 18px;
      background-size:
        5px 5px,
        5px 5px;
      background-repeat: no-repeat;
    }
  }
}

.form-control-group {
  margin-bottom: 1rem;

  input,
  textarea {
    text-align: left;
    font-size: .9rem;
    letter-spacing: 1px;
    height: 45px;
    color: white;
  }

  textarea {
    height: auto;
  }
}


.react-hooks-paginator {
  .page-item {
    .page-link {
      background: transparent;
      border-color: $gold;
      color: $gold;
    }

    &.active,
    &:hover {
      .page-link {
        background-color: $gold;
        border-color: $gold;
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

.gold {
  color: $gold;
}