@import 'assets/scss/variables.scss';

.top-nav {
    display: flex;
    width: 100%;
    max-height: 120px;

    .container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 1.5rem 0;
        border-bottom: 1px solid $gold;
        margin-bottom: 2rem;

        .logo-header {
            max-height: 65px;
            width: auto;
        }

        button.home-toggler {
            @extend .btn-transparent;
            padding: 0;
            width: 65px;
            height: 65px;

        }

        button.menu-toggler {
            @extend .btn-transparent;
            margin: 0 0 0 auto;
            position: relative;

            .count {
                background: $gold;
                width: 20px;
                height: 20px;
                display: block;
                position: absolute;
                border-radius: 20px;
                top: -10px;
                right: 0;
                font-family: $font-btn;
            }
        }

        .smoll {
            letter-spacing: 3px;
            text-transform: uppercase;
            font-size: 1.2rem;
            margin: 0;
            cursor: pointer;
        }

        .nav-container {
            flex: 1;

            .nav-links {
                margin: 0 0 0 auto;

                a {
                    font-family: $font-btn;
                    text-transform: uppercase;
                    padding: 1rem;
                    letter-spacing: 1px;
                    transition: .3s all;

                    &:hover {
                        opacity: .7;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    @include sm {
        width: 100vw;
        position: relative;
        padding: 0;
        //max-height: 65px;
        flex-direction: column;

        .container {
            padding: 0rem 1rem;
            margin-bottom: 0;

            .logo-header {
                max-height: 40px;
            }
        }

        .nav-container {

            .smoll,
            .nav-links {
                display: none;
            }
        }
    }

    .mobile-nav-links {
        display: none;

        @include sm {
            display: flex;

            a {
                width: 50%;
                text-align: center;
                padding: 10px 0;
                font-weight: 800;
            }
        }
    }
}