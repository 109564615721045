@import 'assets/scss/variables.scss';

.product-item {
    display: flex;
    text-align: center;
    flex-direction: column;
    overflow: hidden;
    border-radius: 10px;
    transition: .3s all;
    cursor: pointer;
    img {
        width: 100%;
        height: auto;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
    }
    h4 {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
    }
    span.price {
        font-size: 18px;
        letter-spacing: 1px;
    }
    &:hover {
        opacity: .7;
    }
}

.product-carousel {
    height: 300px;
    display: flex;
    align-items: center;
    overflow: hidden;
}