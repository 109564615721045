@import 'assets/scss/variables.scss';

.cart-items-checkout {
    overflow-y: auto;
    max-height: 76vh;
    ol.list {
      margin: 0;
      padding: 0;
      li {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        position: relative;
        transition: .3s all;
        .top {
          font-family: $font-btn;
          text-transform: uppercase;
          letter-spacing: 2px;
          display: flex;
          justify-content: space-between;
          transition: .3s all;
          .first {
            width: 60%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            text-align: left;
          }
          .second {
            width: 10%;
            text-align: center;
          }
          .third {
            width: 20%;
            text-align: right;
          }
          &.header {
            color: $gold;
          }
          &.totals {
            border-top: 1px solid $gold;
            margin-top: 1rem;
            padding: .5rem 0;
            .first {color: $gold}
          }
        }
        .bottom {
          font-size: .9rem;
          letter-spacing: 1px;
          color: $gold;
          transition: .3s all;
          text-align: left;
        }
        .delete {
          position: absolute;
          right: 0;
          background: $red;
          width: 45px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 30px;
          cursor: pointer;
          visibility: hidden;
          svg {width: 14px; height: auto;}
        }
        &:hover {
          .top,.bottom {opacity: .5; }
          .delete {visibility: visible;}
        }
      }
    }
  }

  input[type="number"]:disabled {
    background: transparent;
    border: none;
  }