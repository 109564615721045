//Variables
$blue: #bfad86;
$yellow: #F2BF27;
$orange: #F07825;
$red: #CE483C;
$green: #0fa538;
$gold: #bfad86;

$gray-base:              #000;
$gray-darker:            lighten($gray-base, 13.5%); // #222
$gray-dark:              lighten($gray-base, 20%);   // #333
$gray:                   lighten($gray-base, 33.5%); // #555
$gray-light:             lighten($gray-base, 46.7%); // #777
$gray-lighter:           lighten($gray-base, 93.5%); // #eee
$black:                  $gray-base;
$white:                  #FFF;

$brand-primary:         $gold; // #337ab7
$brand-success:         $green;
$brand-info:            $gold;
$brand-warning:         $yellow;
$brand-danger:          $red;

@font-face {
    font-family: 'Graphik-Light';
    src: url('../fonts/Graphik-300-Light.woff2') format('woff2'),url('../fonts/Graphik-300-Light.woff') format('woff'),url('../fonts/Graphik-300-Light.otf') format('opentype');
    font-style: normal;
    font-weight: 400;
}
    
@font-face {
    font-family: 'Oak-Bold';
    src: url('../fonts/Oak-Bold.woff2') format('woff2'),url('../fonts/Oak-Bold.woff') format('woff'),url('../fonts/Oak-Bold.ttf') format('opentype');
    font-style: normal;
    font-weight: 400;
}

$body-bg: $black;
$border-radius-base: 4px;
$border-radius-lg: 6px;
$btn-border-radius-base: $border-radius-base;
$input-color-placeholder: $gray-lighter;

$font-body: 'Graphik-Light';
$font-btn: 'Oak-Bold';

//Responsive Utilities
$screen-sm-min: 576px; // Small tablets and large smartphones
$screen-md-min: 768px; // Small tablets
$screen-lg-min: 992px; // Tablets / small desktops
$screen-xl-min: 1200px; // Large desktops

//Media Query Mixins
@mixin sm {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg {
  @media (max-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin xl {
  @media (max-width: #{$screen-xl-min}) {
    @content;
  }
}


.btn-transparent {
    background: transparent;
    border: none;
    outline: none;
}

.font-header {
    font-family: $font-body;
    font-style: italic;
}
